<template>
  <v-content>
    <v-container v-if="item">
      <v-row class="mx-0" align="center" justify="center">
        <v-col :cols="12" class="Card">
          <v-img :src="item.image" contain width="200" height="250" style="margin: auto;"></v-img>
        </v-col>
      </v-row>
      <v-row class="mx-0 my-4 d-flex">
        <v-col :cols="8" class="pa-0">
          <span class="fontheaderBold">{{ item.name }}</span>
        </v-col>
        <v-col :cols="4" class="pa-0">
          <span class="header d-flex justify-end align-start mt-1">THB {{ priceBook(item) }}</span>
        </v-col>
      </v-row>
      <v-row class="mx-0 my-4 d-flex">
        <v-col :cols="8" class="pa-0">
          <span class="description">รหัสสินค้า: {{ item.sku }}</span>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="mx-0 mt-8 mb-12">
        <v-col :cols="7" class="pa-0">
          <v-text-field
            v-model.number="qty"
            outlined
            dense
            hide-details
            single-line
            type="number"
            append-outer-icon="add"
            @click:append-outer="increment()"
            prepend-icon="remove"
            @click:prepend="decrement()"
            @change="changeQty()"
            id="customInput"
          />
        </v-col>
        <v-col :cols="5" class="d-flex justify-end pa-0">
          <span class="fontheaderBold">THB {{ (priceBook(item) * qty).toLocaleString() }}</span>
        </v-col>
      </v-row>
      <div :class="checkAmount">
        <div class="fixButtom">
          <v-col :cols="12" class="cartStatus pa-2" v-if="cart.products">
            <v-icon :size="25">$vuetify.icons.cart </v-icon>
            <span>{{
              `คุณเลือก ${cart.products.length} สินค้าจำนวน ${amountProduct} ชิ้นมูลค่า`
            }}</span>
            <span class="price"> THB {{ cart.grand_total.toLocaleString() }}</span>
          </v-col>
          <ButtonGroup
            :funcLeft="addCart"
            :funcRight="buyNow"
            titleLeft="เพิ่มลงตะกร้า"
            titleRight="ซื้อสินค้า"
          />
        </div>
      </div>
    </v-container>
  </v-content>
</template>

<script>
import ButtonGroup from '../components/ButtonGroup'
import { mapState } from 'vuex'
export default {
  name: 'ProductDetail',
  components: {
    ButtonGroup,
  },
  data: () => {
    return {
      item: {},
      qty: 1,
    }
  },
  methods: {
    async addCart() {
      const self = this
      try {
        const res = await self.$axios({
          method: 'POST',
          url: '/api/cart/add',
          data: {
            social_id: self.$store.state.tokenId,
            store_id: self.$store.state.storeId,
            product_id: self.item.sku,
            qty: self.qty,
            social_channel: 'line',
          },
        })
        if (res.data.status === 'success') {
          const cart = await self.$store.dispatch('getCart')
          if (!self.$store.state.storeId) {
            self.$store.commit('setStoreId', cart.merchant_detail[0].store_id)
            self.$store.commit('setStoreName', cart.merchant_detail[0].name_store)
            self.$store.commit('setStoreType', cart.merchant_detail[0]?.shop_type)
          }
          self.$store.commit('setCart', cart)
        } else {
          self.$modal.show({
            title: 'ไม่สามารถเพิ่มสินค้าลงในตะกร้าได้',
            isOne: true,
          })
        }
      } catch (e) {
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
    async buyNow() {
      const self = this
      try {
        let item = []
        if (self.cart.products || self.cart.products > 0) {
          item = self.cart.products.map(v => {
            if (v.sku === self.item.sku) {
              return {
                sku_id: v.sku,
                qty: v.qty + self.qty,
              }
            } else {
              return {
                sku_id: v.sku,
                qty: v.qty,
              }
            }
          })
        }
        const isDuplicate = await item.some(v => v.sku_id === self.item.sku)
        if (!isDuplicate) item.push({ sku_id: self.item.sku, qty: self.qty })
        if (self.cart.products) {
          const res = await self.$axios({
            method: 'POST',
            url: '/api/cart/replace',
            data: {
              social_id: self.$store.state.tokenId,
              store_id: self.$store.state.storeId,
              new_product: {
                products: item,
              },
            },
          })
          if (res.data.status === 'success') {
            self.$router.push('/cart')
          }
        } else {
          const res = await self.$axios({
            method: 'POST',
            url: '/api/cart/add',
            data: {
              social_id: self.$store.state.tokenId,
              store_id: self.$store.state.storeId,
              product_id: self.item.sku,
              qty: self.qty,
              social_channel: 'line',
            },
          })
          if (res.data.status === 'success') {
            self.$router.push('/cart')
          }
        }
      } catch (e) {
        console.log(e)
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
    increment() {
      const self = this
      self.qty++
    },
    decrement() {
      const self = this
      if (self.qty > 1) self.qty--
      else {
        self.$modal.show({
          title: 'สินค้าไม่ควรเหลือน้อยกว่า 1 ชิ้น',
          isOne: true,
        })
      }
    },
    changeQty() {
      const self = this
      if (!self.qty && self.qty <= 0) {
        self.$modal.show({
          title: 'สินค้าไม่ควรเหลือน้อยกว่า 1 ชิ้น',
          isOne: true,
        })
        self.qty = 1
      }
    },
  },
  computed: {
    ...mapState(['cart', 'storeType']),
    amountProduct() {
      if (this.cart) {
        return this.cart.products.map(item => item.qty).reduce((acc, value) => acc + value, 0)
      }
      return 0
    },
    checkAmount() {
      return this.cart.products !== 0 && 'mb-14'
    },
    priceBook() {
      return item => {
        const priceBook = item.price_book?.find(item => {
          return item.pricebook_type === this.storeType
        })
        return priceBook?.price || item.list_price.toLocaleString()
      }
    },
  },
  mounted() {
    this.item = this.$route.params.item
    if (!this.$route.params.item) this.$router.replace('product')
  },
}
</script>

<style lang="scss" scoped>
.cartStatus {
  display: flex;
  justify-content: space-around;
  background-color: #efefef;
}
</style>
