<template>
  <div>
    <v-row class="buttonBox mx-0">
      <v-btn x-large tile @click="funcLeft" color="primary2" dark :loading="loading">
        {{ titleLeft }}
      </v-btn>
      <v-btn x-large tile dark @click="funcRight" color="primary3" :loading="loading">
        {{ titleRight }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ButtonGroup',
  props: {
    funcLeft: {
      type: Function,
      required: true,
    },
    funcRight: {
      type: Function,
      required: true,
    },
    titleLeft: {
      type: String,
      required: true,
    },
    titleRight: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['loading']),
  },
}
</script>

<style lang="scss" scoped>
// .buttonBox {
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   left: 0;
// }
.v-btn {
  width: auto;
  min-width: 0;
  max-width: 100%;
  flex: 1;
}
</style>
